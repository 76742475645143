<template>
    <div class="wrapper_main">
        <main class="content">
            <div class="content__header sub-page">
                <div class="content__header-left">
                    <router-link  to="/dispatch" class="btn btn-w-shadow btn-sm btn-back">
                        <inline-svg :src="require(`@/assets/img/Arrow-prev.svg`)" />
                    </router-link>
                    <div class="label-icon purple">
                        <!-- <img src="img/Delivery-Truck-Fast.svg" alt="" /> -->
                        <inline-svg :src="require(`@/assets/img/Delivery-Truck-Fast.svg`)" />

                    </div>
                    <div class="heading-wrapper">
                        <h1 class="heading-page-h1">123456789</h1>
                        <ul class="heading-list">
                            <li>
                                <inline-svg :src="require(`@/assets/img/clock-watch.svg`)" />
                                Pending for Approval
                            </li>
                            <li>Esther Howard</li>
                        </ul>
                    </div>
                </div>
                <div class="content__header-right">
                    <BluePlusButton @click="approveDispatch">Approve</BluePlusButton>
                    <button type="button" class="btn btn-w-shadow btn-standart red-color" data-target="reject-dispatch" @click="openRejectDispatchPopup">
                        <inline-svg :src="require(`@/assets/img/Delete-circle.svg`)" />
                        <span>Reject</span>
                    </button>
                </div>
            </div>
            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item"> 
                        <a data-tab="сompanies" class="nav-tab" @click="switchTab('companies')" :class="isActiveTab('companies')"> Companies </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="products" class="nav-tab" @click="switchTab('products')" :class="isActiveTab('products')"> Products </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="conditions" class="nav-tab" @click="switchTab('conditions')" :class="isActiveTab('conditions')"> Conditions </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="documents" class="nav-tab" @click="switchTab('documents')" :class="isActiveTab('documents')"> Documents </a>
                    </li>
                </ul>
            </div>
            <div class="content__body">
                <div class="tab-content">
                    <div id="сompanies" class="tab-content__item" :class="isActiveTab('companies')">
                        <DispatchCompanyTab v-if="isActiveTab('companies')" :companies="dispatch.companies"></DispatchCompanyTab>
                    </div>
                    <div id="products" class="tab-content__item" :class="isActiveTab('products')">
                        <BatchesTab :batches="dispatch.batches" v-if="isActiveTab('products')"></BatchesTab>
                    </div>
                    <div id="conditions" class="tab-content__item" :class="isActiveTab('conditions')">
                        <ConditionsTab :conditions="dispatch.contract_conditions" v-if="isActiveTab('conditions')"></ConditionsTab>
                    </div>
                    <div id="documents" class="tab-content__item" :class="isActiveTab('documents')">
                        <DocumentsTab :documents="dispatch.documents" v-if="isActiveTab('documents')"></DocumentsTab>
                    </div>
                </div>
            </div>
        </main>
        <RejectDispatch v-if="rejectDispatchPopup" @close="closeRejectDispatchPopup" @reject="rejectDispatch" @modify="modifyDispatch" />
        <QrCode v-if="qrCode" @close="closeQrCode"></QrCode>
    </div>
</template>

<script>
import Base from "@/components/base";
import Popups from "@/components/popups";
import Tabs from "@/components/tabs";
import Buttons from "@/components/buttons";
import { mixTabs } from '@/mixins';
import { mixDispatches } from '@/mixins/dispatch';
import { API } from "aws-amplify";
import { updateDispatch } from "@/graphql/mutations";
export default {
    name: "DispatchPageApproval",
    props:["id"],
    components: {
        ...Base,
        ...Popups,
        ...Tabs,
        ...Buttons
    },
    mixins: [mixTabs,mixDispatches],
    data() {
        return {
            rejectDispatchPopup: false,
            qrCode:false,
            pageTabs:['companies', 'products', 'conditions', 'documents'],
            dispatch:{
                
            },
        };
    },
    computed: {
        companyData() {
			return this.$store.state.companyData || {}
		},
    },
    async created(){
        await this.getDispatch(this.id)
    },
    methods: {
        async approveDispatch(){
            const options={title:'Approve Dispatch?',size:'sm',okLabel:"Approve",cancelLabel:"Cancel"} 
            await this.$dialogs.confirm("", options).then(async res=>{
                if(res.ok){
                    await this.changeCompanyStatus(this.id,this.companyData.id,'approved')
                    // this.$router.push("/dispatch/"+this.id)
                }
            })
        },
        openOptions(ev) {            
                let parentElement=ev.target.closest(".spoiler__button")
                parentElement.classList.toggle("active");
                parentElement.nextElementSibling.classList.toggle("active");
        },
        openRejectDispatchPopup() {
            this.rejectDispatchPopup = true;
        },
        closeRejectDispatchPopup() {
            this.rejectDispatchPopup = false;
        },
        rejectDispatch() {

            // ...
        },
        modifyDispatch() {
            this.$router.push(`/dispatch/modify/${this.id}`)
        },
        openQrCode() {
            this.qrCode = 1;
        },
        closeQrCode() {
            this.qrCode = null;
        },
       
    },
};
</script>
